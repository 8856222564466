<template>
    <div>
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
          <div class="d-flex align-items-center">
            <b-spinner small type="grow" variant="dark"></b-spinner>
            <b-spinner type="grow" variant="dark"></b-spinner>
            <b-spinner small type="grow" variant="dark"></b-spinner>
          </div>
        </template>
      </b-overlay>
      <router-link to="/admin/settings" class="btn btn-success">بازگشت</router-link>
      <b-card title="General setting" class="mt-3">
        <form @submit.prevent="itemEdit" id="edit-item">
            <div class="row">
                <div class="col-md-4" v-for="item in items.boolean" :key="item.id">
                    <label class="custom-switch">
                        <input type="checkbox" class="custom-switch-input" v-model="item.value"
                          :disabled="disabled">
                        <span class="custom-switch-indicator"></span>
                        <span class="custom-switch-description">{{item.label}}</span>
                      </label>
                  </div>
            </div>
            <input v-for="(setting, index) in items.boolean" :key="index" type="hidden"  :name="setting.key" :value="setting.value || setting.value == 'on' ? 1 : 0"/>
          <button class="btn btn-success mt-5" type="submit" :disabled="disabled">Update</button>
          <input type="hidden" value="PATCH" name="_method">
        </form>
      </b-card>
    </div>
  </template>
  <script>
    import mixin from '../mixins/mixins'
    export default {
      mixins: [mixin],
      data() {
        return {
          items: [],
          show: true,
          url: '/api/admin/settings',
          free_amount: '',
          title: 'تنظیمات'
        }
      },
      methods: {
        loadSetting() {
          this.$axios.get(this.$root.baseUrl + '/api/admin/settings/shipping')
            .then(response => {
              this.items = response.data.data
              this.show = false
            })
        },
        itemEdit() {
          this.disabled = true
          let form = document.getElementById('edit-item')
          let formData = new FormData(form)
          this.$axios.post(this.$root.baseUrl + this.url, formData)
            .then(response => {
              this.$root.success_notification(response.data.message)
              this.$root.$set(this.items.data.data, this.edit.index, response.data.data)
              this.$root.$emit('bv::hide::modal', 'edit-modal')
            })
            .catch(error => {
              this.$root.error_notification(error)
            })
            .finally(() => {
              this.disabled = false
            })
        },
      },
      created() {
        this.loadSetting()
      },
      
    }
  </script>